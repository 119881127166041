import React, { useState, useEffect } from 'react'
import {
  map,
  isEmpty,
  filter,
  isUndefined,
  findIndex,
  some,
  isEqual,
  find,
} from 'lodash'
import { Form, message } from 'antd'
import { navigate, Link } from 'gatsby'
import axios from 'axios'
import LogRocket from 'logrocket'
import PropTypes from 'prop-types'

import SEO from 'components/SEO'
import LayoutSignup from 'components/LayoutSignup'
import PersonalForm from 'components/Signup/PersonalForm'
import OrganizationForm from 'components/Signup/OrganizationForm'
import Steps from 'components/Steps'
import PaymentForm from 'components/Signup/PaymentForm'
import SubscriptionDetailsCard from 'components/Signup/SubscriptionDetailsCard'

import LiveLogo from 'images/landing-page/icons/live-red-icon.svg'
import {
  validationFields,
  WELCOME_ROUTE,
  ORG_SLUG_FIELD,
  EMAIL_FIELD,
} from 'components/Signup/constants'
import endpoints from 'endpoints'
import requestURL from 'utils/requestURL'
import { TABS } from 'components/Signup/PaymentForm/contants'
import { GA_EVENTS } from 'components/LandingPage/constants'

message.config({
  top: 50,
  duration: 5,
  maxCount: 3,
  rtl: false,
})

const { TIERS } = TABS

const SignUp = ({ location }) => {
  const [form] = Form.useForm()
  const [signUpIndex, setSignUpIndex] = useState(0)
  const [isDomainCheckLoading, setDomainCheckLoading] = useState(false)
  const [isDomainAvailable, setDomainAvailable] = useState(false)
  const [isRegisterLoading, setRegisterLoading] = useState(false)
  const [subscriptionTiers, setSubscriptionTiers] = useState(null)
  const [selectedTier, setSelectedTier] = useState(null)
  const [stripeValues, setStripeValues] = useState(null)
  const [activePaymentsTab, setActivePaymentsTab] = useState(TIERS)
  const { state } = location
  const { source } = state || {}

  useEffect(() => {
    if (window && window.gtag) {
      window.gtag('event', GA_EVENTS.SIGN_UP_STARTED, {
        source,
      })
    }
  })

  useEffect(() => {
    if (!isUndefined(process.env.LOG_ROCKET_PROJECT_ID)) {
      LogRocket.init(process.env.LOG_ROCKET_PROJECT_ID)
    }
  }, [])

  useEffect(() => {
    axios
      .get(requestURL(endpoints.subscriptionPlans))
      .then(response => {
        const { data } = response
        if (!isEmpty(data)) {
          setSubscriptionTiers(data)
        }
      })
      .catch(errors => {
        onShowErrors(errors)
      })
  }, [])

  async function onSubmit() {
    const updatedValues = {
      ...form.getFieldsValue(),
      ...stripeValues,
      ...selectedTier,
    }

    setRegisterLoading(true)
    await axios
      .post(requestURL(endpoints.register), updatedValues)
      .then(response => {
        const { data } = response
        if (!isEmpty(data)) {
          setRegisterLoading(false)
          navigate(WELCOME_ROUTE, {
            state: {
              userRegistered: data.success,
              org: form.getFieldValue(ORG_SLUG_FIELD),
              email: form.getFieldValue(EMAIL_FIELD),
              tier: find(subscriptionTiers.results, {
                id: selectedTier.product_id,
              }).name,
            },
          })
        }
      })
      .catch(errors => {
        onShowErrors(errors)
        setRegisterLoading(false)
      })
  }

  function onChangeStep(step) {
    setSignUpIndex(step)
    if (isEqual(step, 2)) {
      setActivePaymentsTab(TIERS)
    }
  }

  function onNext(values) {
    if (!isEmpty(values)) {
      setStripeValues(values)
      setSignUpIndex(signUpIndex + 1)
      return
    }

    if (!isEmpty(form)) {
      form
        .validateFields(validationFields[signUpIndex])
        .then(() => {
          if (signUpIndex === 1 && !isDomainAvailable) {
            message.error('Domain is not available')
          } else {
            setSignUpIndex(signUpIndex + 1)
          }
        })
        .catch(({ errorFields }) => {
          const errors = map(errorFields, error => ({
            name: error.field,
            errors: [error.message],
          }))
          form.setFields(errors)
        })
    }
  }

  // function onPrevious() {
  //   setSignUpIndex(signUpIndex - 1)
  // }

  // async function onSkipPayment() {
  //   setRegisterLoading(true)
  //   const values = form.getFieldsValue()
  //   await axios
  //     .post(requestURL(endpoints.register), values)
  //     .then(response => {
  //       const { data } = response
  //       if (!isEmpty(data)) {
  //         setRegisterLoading(false)
  //         navigate(KEYS_ROUTE, {
  //           state: {
  //             data,
  //             values,
  //           },
  //         })
  //       }
  //     })
  //     .catch(errors => {
  //       setRegisterLoading(false)
  //       onShowErrors(errors)
  //     })
  // }

  function onShowErrors(error) {
    if (error.response) {
      const errors = filter(error.response.data.errors, err => !isEmpty(err))
      map(errors, err => message.error(`${err.field} - ${err.message}`))
      /* eslint-disable implicit-arrow-linebreak, function-paren-newline */
      const errorIndex = findIndex(validationFields, fields =>
        some(fields, field => field === errors[0].field),
      )
      /* eslint-enable implicit-arrow-linebreak, function-paren-newline */

      if (errorIndex !== -1) {
        setSignUpIndex(errorIndex)
        const formErrors = map(errors, err => ({
          name: err.field,
          errors: [err.message],
        }))
        form.setFields(formErrors)
      }
    } else {
      message.error(error.message)
    }
  }

  function onCheckDomainSlug(slug) {
    setDomainCheckLoading(true)
    setDomainAvailable(false)
    axios
      .post(requestURL(endpoints.organizationSlug), {
        slug,
      })
      .then(response => {
        const { data } = response
        if (!isEmpty(data)) {
          const { available } = data
          setDomainCheckLoading(false)
          if (available) {
            setDomainAvailable(true)
          } else {
            setDomainAvailable(false)
            message.error('Domain is not available')
          }
        }
      })
      .catch(error => {
        message.error(error.message)
      })
  }

  function renderSEOTitle() {
    if (signUpIndex === 0) {
      return 'Personal Details'
    }
    if (signUpIndex === 1) {
      return 'Organization Details'
    }
    return 'Payment Details'
  }

  return (
    <LayoutSignup>
      <SEO title={`Get Started - ${renderSEOTitle()}`} />
      <header>
        <div className="logo">
          <Link to="/">
            <LiveLogo />
          </Link>
        </div>
      </header>
      <div className="signup-form">
        <section className="form-container">
          <Steps step={signUpIndex + 1} onChangeStep={onChangeStep} />
          <Form form={form} onFinish={onSubmit}>
            <div className="form">
              <PersonalForm
                isActive={signUpIndex === 0}
                isComplete={signUpIndex > 0}
                onNext={onNext}
              />
              <PaymentForm
                isActive={signUpIndex === 1}
                isComplete={signUpIndex > 1}
                subscriptionTiers={subscriptionTiers}
                setSelectedTier={setSelectedTier}
                activeTab={activePaymentsTab}
                setActiveTab={setActivePaymentsTab}
                // onSkipPayment={onSkipPayment}
                onNext={onNext}
                // onPrevious={onPrevious}
              />
              <OrganizationForm
                form={form}
                isActive={signUpIndex === 2}
                isComplete={signUpIndex > 2}
                onCheckDomainSlug={onCheckDomainSlug}
                isDomainAvailable={isDomainAvailable}
                isDomainCheckLoading={isDomainCheckLoading}
                isRegisterLoading={isRegisterLoading}
                onNext={onSubmit}
              />

              {signUpIndex === 0 && (
                <span className="sign-in-message">
                  Already have an account?{' '}
                  <a href={`https://${process.env.FRONTEND_DOMAIN}/login`}>
                    Sign in
                  </a>
                </span>
              )}
            </div>
          </Form>
          <div className="bottom-row">
            <div className="rect" />
            <SubscriptionDetailsCard />
          </div>
        </section>
      </div>
      {/* <footer className="signup-footer" /> */}
    </LayoutSignup>
  )
}

SignUp.propTypes = {
  location: PropTypes.object,
}

export default SignUp

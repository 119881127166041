import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { useWindowSize } from 'react-use'
import { isEmpty, map, trim, filter, flatten } from 'lodash'
import { Button, message, Row, Input } from 'antd'
import cx from 'classnames'
import axios from 'axios'
import { CheckCircleOutlined } from '@ant-design/icons'

import requestURL from 'utils/requestURL'
import getPromoCodeSuccessText from 'utils/getPromoCodeSuccessText'
import { rules } from 'components/Signup/constants'
import endpoints from 'endpoints'

const { Search } = Input

const StripeForm = ({
  // onSkipPayment,
  onSubmit,
  // onPrevious,
}) => {
  const { width: windowWidth } = useWindowSize()
  const stripe = useStripe()
  const elements = useElements()
  const [cardHolder, setCardHolder] = useState('')
  const [couponCode, setCouponCode] = useState('')
  const [verifyCouponLoading, setVerifyCouponLoading] = useState(false)
  const [appliedCoupon, setAppliedCoupon] = useState({})
  const [isCreateTokenLoading, setIsCreateTokenLoading] = useState(false)

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontFamily: 'GTWalsheim, sans-serif',
        fontWeight: '500',
        fontSize: '16px',
        color: '#22174A',
        '::placeholder': {
          color: 'rgba(133, 133, 133, 0.6)',
        },
      },
      empty: {
        fontWeight: '300',
        color: '#858585',
      },
      complete: {
        color: '#4F25C6',
      },
    },
  }

  useEffect(() => {
    if (!isEmpty(elements)) {
      const cardElement = elements.getElement(CardElement)
      if (windowWidth >= 768) {
        cardElement.update({ style: { base: { fontSize: '20px' } } })
      } else {
        cardElement.update({ style: { base: { fontSize: '16px' } } })
      }
    }
  }, [windowWidth])

  useEffect(() => {
    if (!isEmpty(appliedCoupon)) {
      const { success: verifyCouponSuccess } = appliedCoupon
      setCouponCode('')
      if (verifyCouponSuccess) {
        message.success('Coupon has been applied')
      } else {
        message.error('Invalid Coupon!')
        setCouponCode('')
        setAppliedCoupon({})
      }
    }
  }, [appliedCoupon])

  async function onCheckErrors() {
    const newErrors = []
    if (isEmpty(trim(cardHolder))) {
      newErrors.push(rules.fullName)
    }

    return newErrors
  }

  function onSubmitPayment(event) {
    event.persist()
    setIsCreateTokenLoading(true)
    onCreateToken()
  }

  async function onCreateToken() {
    const formErrors = await onCheckErrors()
    if (!isEmpty(formErrors)) {
      setIsCreateTokenLoading(false)
      map(formErrors, error => {
        message.error(error)
      })
      return
    }

    const tokenOptions = {
      name: cardHolder,
    }
    const cardElement = elements.getElement(CardElement)
    await stripe.createToken(cardElement, tokenOptions).then(result => {
      if (result.error) {
        message.error(result.error.message)
        setIsCreateTokenLoading(false)
      } else {
        const {
          token: { id },
        } = result
        const { coupon } = appliedCoupon
        setIsCreateTokenLoading(false)
        onSubmit({
          card_token: id,
          ...(!isEmpty(coupon) && {
            coupon: coupon.code,
          }),
        })
      }
    })
  }

  async function handleSubmitCoupon() {
    setVerifyCouponLoading(true)
    const values = {
      coupon: couponCode,
    }
    await axios
      .post(requestURL(endpoints.verifyCoupon), values)
      .then(response => {
        const { data } = response
        if (!isEmpty(data)) {
          setAppliedCoupon(data)
          setVerifyCouponLoading(false)
        }
      })
      .catch(errors => {
        setAppliedCoupon({})
        onCouponError(errors)
        setVerifyCouponLoading(false)
      })
  }

  function onCouponError(errors) {
    const newErrors = filter(errors, error => !isEmpty(error))
    const flattenErrors = flatten(newErrors)
    map(flattenErrors, err => message.error(`${err.field} - ${err.message}`))
  }

  function onChangeCouponCode(value) {
    setCouponCode(value.target.value)
  }

  function setFullName(event) {
    setCardHolder(event.currentTarget.value)
  }

  function getAppliedCoupon() {
    if (!isEmpty(appliedCoupon)) {
      const { success: verifyCouponSuccess } = appliedCoupon
      if (verifyCouponSuccess) {
        return appliedCoupon
      }
      return null
    }

    return null
  }

  function renderPromoButton() {
    return (
      <Button
        htmlType="button"
        className="form-submit-button"
        disabled={!isEmpty(getAppliedCoupon()) && isEmpty(couponCode)}
      >
        {!isEmpty(getAppliedCoupon()) && isEmpty(couponCode)
          ? 'Promo Applied!'
          : 'Apply'}
      </Button>
    )
  }

  function renderPromoCodeSuccess() {
    if (!isEmpty(appliedCoupon)) {
      const { success: verifyCouponSuccess, coupon } = appliedCoupon
      if (verifyCouponSuccess) {
        return (
          <div className="promo-code-success">
            <CheckCircleOutlined />
            <p className="promo-code-success-text">
              {getPromoCodeSuccessText(coupon)}
            </p>
          </div>
        )
      }
    }
    return null
  }

  return (
    <>
      <div className="fieldset-container --one-column">
        <div className="fieldset">
          <div
            className={cx({
              'ant-form-item-has-success': cardHolder.length >= 1,
            })}
          >
            <label htmlFor="fullName">Full Name</label>
            <input
              id="fullName"
              name="fullName"
              placeholder="Full Name"
              onChange={setFullName}
            />
          </div>
        </div>
        <div className="fieldset">
          <label htmlFor="fullName">Card Number</label>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </div>
        <div className="fieldset">
          <label htmlFor="promoCode">Promo Code</label>
          <Search
            placeholder="Code"
            enterButton={renderPromoButton()}
            size="large"
            onChange={onChangeCouponCode}
            value={couponCode}
            onSearch={handleSubmitCoupon}
            loading={verifyCouponLoading}
            className={cx('paywall-coupon', {
              '--applied': !isEmpty(appliedCoupon) && isEmpty(couponCode),
            })}
          />
          {renderPromoCodeSuccess()}
        </div>
      </div>
      <div className="form-buttons">
        {/* <Button
          className="form-submit-button --secondary --back"
          htmlType="button"
          onClick={onPrevious}
        >
          Back
        </Button> */}
        <Row justify="end">
          {/* <Button
            className="form-submit-button --secondary"
            htmlType="button"
            onClick={onSkipPayment}
            disabled={isRegisterLoading}
          >
            Skip for now
          </Button> */}
          <Button
            className="form-submit-button"
            htmlType="button"
            onClick={onSubmitPayment}
            loading={isCreateTokenLoading}
            disabled={isCreateTokenLoading}
          >
            Next: Organization Details
          </Button>
        </Row>
      </div>
    </>
  )
}

StripeForm.propTypes = {
  // onPrevious: PropTypes.func,
  // onSkipPayment: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default StripeForm

import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Row, Button } from 'antd'
import { debounce, isEmpty, toLower } from 'lodash'
import { LoadingOutlined } from '@ant-design/icons'
import cx from 'classnames'

import TickIcon from 'images/icons/tick.svg'
import FailIcon from 'images/icons/fail.svg'
import {
  NO_SPECIAL_CHARACTERS,
  DEBOUNCE_TIMER,
  rules,
} from 'components/Signup/constants'

const OrganizationForm = ({
  isActive,
  isComplete,
  onCheckDomainSlug,
  isDomainCheckLoading,
  isDomainAvailable,
  form,
  onNext,
  isRegisterLoading,
  // onPrevious,
}) => {
  const [isDomainEmpty, setDomainEmpty] = useState(true)

  const debounceRequest = useCallback(
    debounce(value => onCheckDomainSlug(value), DEBOUNCE_TIMER),
    [],
  )

  function onChangeSlug(event) {
    // only allow lower case letters
    form.setFieldsValue({ org_slug: toLower(event.target.value) })
    event.persist()
    if (!isEmpty(event.target.value) && event.currentTarget.value.length >= 2) {
      setDomainEmpty(false)
      debounceRequest(event.target.value)
    }
    if (event.currentTarget.value.length < 2) {
      setDomainEmpty(true)
    }
  }

  function onKeyPress(event) {
    const specialCharRegex = new RegExp(NO_SPECIAL_CHARACTERS)
    const pressedKey = String.fromCharCode(
      !event.charCode ? event.which : event.charCode,
    )
    if (!specialCharRegex.test(pressedKey)) {
      event.preventDefault()
      return false
    }

    return null
  }

  function renderDomainCheck() {
    if (!isDomainEmpty) {
      if (isDomainCheckLoading) {
        return (
          <span className="loading">
            <LoadingOutlined />
          </span>
        )
      }
      if (isDomainAvailable) {
        return <TickIcon className="confirmation-icon" />
      }
      return <FailIcon className="confirmation-icon" />
    }

    return null
  }

  return (
    <div
      className={cx('form-section', {
        'is-active': isActive,
        'is-complete': isComplete,
      })}
    >
      <div className="fieldset-container --one-column">
        <div className="fieldset">
          <Form.Item
            label="Choose your domain"
            name="org_slug"
            shouldUpdate
            rules={[
              { max: 255, message: rules.maxCharacters },
              { required: true, message: rules.orgnaizationSlug },
            ]}
          >
            <Input
              className="domain"
              suffix=".sellwith.live"
              onChange={onChangeSlug}
              onKeyPress={onKeyPress}
              placeholder="yourdomain"
              prefix={renderDomainCheck()}
            />
          </Form.Item>
        </div>
      </div>
      <div className="form-buttons">
        <Row justify="space-between">
          {/* <Button
            className="form-submit-button --secondary --back"
            htmlType="button"
            onClick={onPrevious}
          >
            Back
          </Button> */}
          <Form.Item noStyle shouldUpdate>
            {({ getFieldsValue }) => {
              const { org_slug: slug } = getFieldsValue()
              const formIsComplete = !!slug && isDomainAvailable
              return (
                <Button
                  disabled={!formIsComplete || isRegisterLoading}
                  className="form-submit-button"
                  htmlType="button"
                  onClick={onNext}
                  loading={isRegisterLoading}
                >
                  Create Account
                </Button>
              )
            }}
          </Form.Item>
        </Row>
      </div>
    </div>
  )
}

OrganizationForm.propTypes = {
  isActive: PropTypes.bool,
  isComplete: PropTypes.bool,
  isDomainCheckLoading: PropTypes.bool,
  isDomainAvailable: PropTypes.bool,
  onCheckDomainSlug: PropTypes.func,
  onNext: PropTypes.func,
  // onPrevious: PropTypes.func,
  form: PropTypes.object,
  isRegisterLoading: PropTypes.bool,
}

export default OrganizationForm

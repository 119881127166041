import { promoCodeDurationTypes } from 'components/Signup/constants'

const { once, forever, repeating } = promoCodeDurationTypes

const getOff = coupon => {
  const { percent_off: percentOff, amount_off: amountOff } = coupon

  if (percentOff) {
    return `${percentOff}%`
  }
  if (amountOff) {
    return `$${amountOff}`
  }
  return null
}

// https://stripe.com/docs/billing/subscriptions/discounts
const getDuration = coupon => {
  const { duration, duration_in_months: durationInMonths } = coupon

  if (duration === once) {
    return 'off first time.'
  }
  if (duration === forever) {
    return 'off (forever)'
  }
  if (duration === repeating) {
    const monthPostfix = durationInMonths === 1 ? 'month' : 'months'
    return `off for ${durationInMonths} ${monthPostfix}`
  }
  return null
}

const getPromoCodeSuccessText = coupon => {
  const off = getOff(coupon)
  const duration = getDuration(coupon)

  return `${off} ${duration}`
}

export default getPromoCodeSuccessText

import React from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Row, Button } from 'antd'
import { Link } from 'gatsby'
import cx from 'classnames'

import TickIcon from 'images/icons/tick.svg'
import { rules } from 'components/Signup/constants'

const PersonalForm = ({ isActive, isComplete, onNext }) => (
  <div
    className={cx('form-section', {
      'is-active': isActive,
      'is-complete': isComplete,
    })}
  >
    <h2 className="form-heading">Ready to go Live?</h2>
    <div className="fieldset-container --one-column">
      <div className="fieldset">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { type: 'email', message: rules.validEmail },
            { max: 255, message: rules.maxCharacters },
            { required: true, message: rules.email },
          ]}
        >
          <Input
            placeholder="Email"
            suffix={<TickIcon className="confirmation-icon" />}
          />
        </Form.Item>
      </div>
    </div>
    <div className="fieldset-container --one-column">
      <div className="fieldset">
        <Form.Item
          label="Password"
          name="password"
          className="secondary-color"
          rules={[
            { required: true, message: rules.password },
            { min: 6, message: rules.passwordLength },
          ]}
        >
          <Input.Password
            placeholder="Password"
            visibilityToggle={false}
            prefix={<TickIcon className="confirmation-icon" />}
          />
        </Form.Item>
      </div>
    </div>
    <div>
      <p className="terms-info">
        By clicking Next, you agree to our{' '}
        <Link to="/terms" target="_blank">
          Terms of Service
        </Link>
        .{' '}
      </p>
      <Row justify="space-between">
        <Form.Item noStyle shouldUpdate>
          {({ getFieldsValue }) => {
            const { email, password } = getFieldsValue()
            const formIsComplete = !!password && !!email
            return (
              <Button
                disabled={!formIsComplete}
                className="form-submit-button"
                htmlType="button"
                onClick={onNext}
              >
                Next: Package & Payment
              </Button>
            )
          }}
        </Form.Item>
      </Row>
    </div>
  </div>
)

PersonalForm.propTypes = {
  isActive: PropTypes.bool,
  isComplete: PropTypes.bool,
  onNext: PropTypes.func,
}

export default PersonalForm

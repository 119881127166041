import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { isEmpty, isEqual, map } from 'lodash'
import { Col, Row } from 'antd'
import cx from 'classnames'

import StripeForm from 'components/Signup/StripeForm'
import TierItem from 'components/Tieritem'
import { TABS } from './contants'

const fonts = [
  {
    style: 'normal',
    family: 'GTWalsheim',
    src: 'fonts/GT-Walsheim-Light.woff2',
    weight: '300',
  },
]

const { TIERS, PAYMENT } = TABS

const PaymentForm = ({
  isActive,
  isComplete,
  onSkipPayment,
  onNext,
  onPrevious,
  subscriptionTiers,
  setSelectedTier,
  activeTab,
  setActiveTab,
}) => {
  const [stripeObject, setStripeObject] = useState({})

  const isTiersView = isEqual(activeTab, TIERS)
  const { results: tiersResult } = subscriptionTiers || {}

  useEffect(() => {
    if (isEmpty(stripeObject)) {
      const stripePromise = loadStripe(process.env.STRIPE_KEY)
      setStripeObject({
        stripePromise,
      })
    }
  }, [])

  useEffect(() => {
    if (!isActive) {
      setActiveTab(TIERS)
    }
  }, [isActive])

  function onTierSelect(values) {
    setActiveTab(PAYMENT)
    setSelectedTier(values)
  }

  function renderStripePayment() {
    if (!isEmpty(stripeObject)) {
      const { stripePromise } = stripeObject
      return (
        <Elements stripe={stripePromise} fonts={fonts}>
          <StripeForm
            onSkipPayment={onSkipPayment}
            onSubmit={onNext}
            onPrevious={onPrevious}
          />
        </Elements>
      )
    }

    return null
  }

  function renderTiers() {
    if (!isEmpty(tiersResult)) {
      return (
        <Row className="tiers-container">
          {map(tiersResult, (tier, index) => (
            <Col
              key={tier.name}
              className={cx('tier-item', {
                'is-best-value': tier.is_best_value,
              })}
              xs={24}
              md={12}
            >
              <TierItem
                tier={tier}
                previousTierName={index > 0 ? tiersResult[index - 1].name : ''}
                onSelect={onTierSelect}
              />
            </Col>
          ))}
        </Row>
      )
    }

    return null
  }

  return (
    <>
      <div
        className={cx('form-section is-tiers-view', {
          'is-active': isActive && isTiersView,
          'is-complete': !isTiersView,
        })}
      >
        {renderTiers()}
      </div>
      <div
        className={cx('form-section', {
          'is-active': isActive && !isTiersView,
          'is-complete': isComplete,
        })}
      >
        {renderStripePayment()}
      </div>
    </>
  )
}

PaymentForm.propTypes = {
  isActive: PropTypes.bool,
  isComplete: PropTypes.bool,
  onSkipPayment: PropTypes.func,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  subscriptionTiers: PropTypes.object,
  setSelectedTier: PropTypes.func,
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func,
}

export default PaymentForm
